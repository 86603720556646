import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import ImageCrop from "../ImageCrop";
import File from "../FileField";
import FieldLoader from "../FieldLoader";
import { getOneCompany, updateCompany } from "../../../../redux/slices/company";
// import { time_zones } from "../../../../constants/time-zones";
import { currencies } from "../../../../constants/Currencies";

import BackdropLoading from "../../../../components/reusableComponents/BackdropLoading";
import Alert from "../../../../components/alerts/Alert";
import { hideModal, showModal } from "../../../../redux/slices/modal";
import { useFileUploadProgress } from "../../../../providers/FileUploader";
import { EntityEnum } from "../../../../services/media";

const keys = {
  logo: "",
  business_name: "",
  slogan: "",
  street: "",
  street_number: "",
  city: "",
  state: "",
  country: "",
  zip: "",
  website: "",
  tax_identification: "",
  timezone: "",
  primary_color: "",
  secondary_color: "",
  description: "",
  policies_and_terms: "",
  treatment_contract: "",
  currency: "",
  emergency_email: "",
};

const Component = ({
  user,
  company,
  companyLoading,
  companyError,
  companySuccess,
  companyUpdate,
  companyUpdateLoading,
  companyUpdateError,
  companyUpdateSuccess,
  isOnboarding = false,
  handleStepComplete = () => {},
}) => {
  const { uploadFiles, areFiles, resetFileUploadProgress } =
    useFileUploadProgress();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(keys);
  const [values, setValues] = useState(keys);
  const [errors, setErrors] = useState({});
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const handleChange = (event) => {
    if (!event.target) {
      console.error("Event was not found.");
    }

    const { name, value, type, required } = event.target;
    console.log("handleChange", { name, value, type, required });

    setValues((prevValues) => {
      const newValues = { ...prevValues };

      if (value === "") {
        delete newValues[name];
      } else {
        newValues[name] = value;
      }

      console.log("handleChange", { newValues });
      return newValues;
    });

    // Validate the required field
    if (
      ((type === "text" || type === "textarea") &&
        value.trim() === "" &&
        required) ||
      (type === "file" && !value && required)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "This field is required",
      }));
    } else {
      // Clear the error if the field is not empty
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const thereAreFilesToUpload =
      values &&
      ((Array.isArray(values.logo) && areFiles(values.logo)) ||
        (Array.isArray(values?.treatment_contract) &&
          areFiles(values?.treatment_contract)));

    const logoExists = Array.isArray(values.logo) && areFiles(values.logo);
    const treatmentContractExists =
      Array.isArray(values?.treatment_contract) &&
      areFiles(values?.treatment_contract);

    if (logoExists && treatmentContractExists) {
      setIsAlert({
        open: true,
        severity: "warning",
        message: t(
          "Messages.Please upload one file at a time, either a logo or a treatment contract"
        ),
      });

      // Set treatment_contract to null
      setValues((prevValues) => ({
        ...prevValues,
        treatment_contract: null,
      }));

      return;
    }

    if (thereAreFilesToUpload) {
      dispatch(showModal("modal-wait-a-minute"));
    }

    try {
      if (thereAreFilesToUpload) {
        console.log("thereAreFilesToUpload", thereAreFilesToUpload);

        const uploadedFiles = await uploadFiles(
          {
            ...(values?.logo &&
              values?.logo.length > 0 && { logo: values?.logo }),
            ...(values?.treatment_contract &&
              values?.treatment_contract.length > 0 && {
                treatment_contract: values?.treatment_contract,
              }),
          },
          EntityEnum.COMPANY
        );

        const updatedValues = {
          id: company.id,
          ...values,
          logo:
            uploadedFiles?.logo && uploadedFiles?.logo[0]
              ? uploadedFiles?.logo[0]
              : null,
          treatment_contract:
            uploadedFiles?.treatment_contract &&
            uploadedFiles?.treatment_contract[0]
              ? uploadedFiles?.treatment_contract[0]
              : null,
        };

        dispatch(updateCompany(updatedValues));
        if (isOnboarding) {
          handleStepComplete();
        }
        setIsAlert({
          open: true,
        });
      } else {
        const { logo, treatment_contract, ...rest } = values;

        const updatedValues = {
          id: company.id,
          ...rest,
        };

        dispatch(updateCompany(updatedValues));
        if (isOnboarding) {
          handleStepComplete();
        }
        setIsAlert({
          open: true,
        });
      }
    } catch (error) {
      console.error("Error during file uploads:", error);
    } finally {
      dispatch(hideModal());
    }
  };

  useEffect(() => {
    let initialValues = {};
    if (!!company) {
      for (const key of Object.keys(keys)) {
        initialValues[key] = company[key];
      }
    }

    setInitialValues(initialValues);
    setValues(initialValues);
  }, [company]);

  useEffect(() => {
    dispatch(getOneCompany({ id: user?.auth?.company_ids[0] }));
  }, [user]);

  return (
    <>
      <h4 className="main-title-treatments">{t("CompanyForm.Edit Company")}</h4>
      <Form noValidate id="edit-company-form" onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>{t("CompanyForm.Company Logo")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <ImageCrop
              required
              name="logo"
              defaultValue={initialValues["logo"]?.url}
              onInput={handleChange}
              showZoomControl
              showPreview
              disabled={companyUpdateLoading}
            />
          </FieldLoader>
          {!!errors["logo"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>{t("CompanyForm.Company name")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              required
              type="text"
              name="business_name"
              defaultValue={initialValues["business_name"]}
              onInput={handleChange}
              disabled={companyUpdateLoading}
            />
          </FieldLoader>
          {!!errors["business_name"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>{t("CompanyForm.Emergency email")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              required
              type="text"
              name="emergency_email"
              defaultValue={initialValues["emergency_email"]}
              onInput={handleChange}
              disabled={companyUpdateLoading}
            />
          </FieldLoader>
          {!!errors["emergency_email"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>{t("CompanyForm.Slogan")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              type="text"
              name="slogan"
              defaultValue={initialValues["slogan"]}
              onInput={handleChange}
              disabled={companyUpdateLoading}
            />
          </FieldLoader>
          {!!errors["slogan"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>{t("CompanyForm.Street")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              type="text"
              name="street"
              defaultValue={initialValues["street"]}
              onInput={handleChange}
              disabled={companyUpdateLoading}
            />
          </FieldLoader>
          {!!errors["street"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>{t("CompanyForm.Street number")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              type="text"
              name="street_number"
              defaultValue={initialValues["street_number"]}
              onInput={handleChange}
              disabled={companyUpdateLoading}
            />
          </FieldLoader>
          {!!errors["street_number"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>{t("CompanyForm.City")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              type="text"
              name="city"
              defaultValue={initialValues["city"]}
              onInput={handleChange}
              disabled={companyUpdateLoading}
            />
          </FieldLoader>
          {!!errors["city"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>{t("CompanyForm.State")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              type="text"
              name="state"
              defaultValue={initialValues["state"]}
              onInput={handleChange}
              disabled={companyUpdateLoading}
            />
          </FieldLoader>
          {!!errors["state"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>{t("CompanyForm.Country")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              type="text"
              name="country"
              defaultValue={initialValues["country"]}
              onInput={handleChange}
              disabled={companyUpdateLoading}
            />
          </FieldLoader>
          {!!errors["country"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>{t("CompanyForm.Zip code")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              type="text"
              name="zip"
              defaultValue={initialValues["zip"]}
              onInput={handleChange}
              disabled={companyUpdateLoading}
            />
          </FieldLoader>
          {!!errors["zip"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>{t("CompanyForm.Website")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              type="text"
              name="website"
              defaultValue={initialValues["website"]}
              onInput={handleChange}
              disabled={companyUpdateLoading}
            />
          </FieldLoader>
          {!!errors["website"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>{t("CompanyForm.Terms and conditions URL")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              type="text"
              name="policies_and_terms"
              defaultValue={initialValues["policies_and_terms"]}
              onInput={handleChange}
              disabled={companyUpdateLoading}
            />
          </FieldLoader>
          {!!errors["policies_and_terms"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>{t("CompanyForm.Tax identification")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              type="text"
              name="tax_identification"
              defaultValue={initialValues["tax_identification"]}
              onInput={handleChange}
              disabled={companyUpdateLoading}
            />
          </FieldLoader>
          {!!errors["tax_identification"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="custom-select">
          <Form.Label>{t("CompanyForm.Company Currency")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              as="select"
              className="form-select"
              type="select"
              name="currency"
              value={values["currency"]}
              onChange={handleChange}
              disabled={companyUpdateLoading}
              style={{ cursor: "pointer" }}
            >
              <option className="d-none" value="">
                {t("Buttons.Select Option")}
              </option>
              {currencies.map((option, i) => (
                <option key={i} value={option.symbol}>
                  ({option.symbol}) {option.name}
                </option>
              ))}
            </Form.Control>
          </FieldLoader>
          {!!errors["currency"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        {/* <Form.Group className="mb-3" controlId="custom-select">
          <Form.Label>{t("CompanyForm.Time zone")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              as="select"
              className="form-select"
              type="select"
              name="timezone"
              value={values["timezone"]}
              onChange={handleChange}
              disabled={companyUpdateLoading}
              style={{ cursor: "pointer" }}
            >
              <option className="d-none" value="">
                {t("Buttons.Select Option")}
              </option>
              {time_zones.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </Form.Control>
          </FieldLoader>
          {!!errors["timezone"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group> */}

        <Form.Group className="mb-3" controlId="primary_color">
          <Form.Label>{t("CompanyForm.Primary color")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              type="color"
              name="primary_color"
              value={values["primary_color"]}
              onInput={handleChange}
              disabled={companyUpdateLoading}
            />
          </FieldLoader>
          {!!errors["primary_color"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="secondary_color">
          <Form.Label>{t("CompanyForm.Secondary color")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              type="color"
              name="secondary_color"
              value={values["secondary_color"]}
              onInput={handleChange}
              disabled={companyUpdateLoading}
            />
          </FieldLoader>
          {!!errors["secondary_color"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        {/* <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              as="textarea"
              name="description"
              defaultValue={initialValues["description"]}
              onInput={handleChange}
              disabled={companyUpdateLoading}
            />
          </FieldLoader>
          {!!errors["description"] && <Form.Control.Feedback type="invalid">Required field</Form.Control.Feedback>}
        </Form.Group> */}

        <Form.Group className="mb-3">
          <Form.Label>{t("CompanyForm.Treatment Contract")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <File
              name="treatment_contract"
              defaultValue={initialValues["treatment_contract"]?.url}
              onInput={handleChange}
              disabled={companyUpdateLoading}
            />
          </FieldLoader>
          {!!errors["treatment_contract"] && (
            <Form.Control.Feedback type="invalid">
              {" "}
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <div style={{ height: 40 }} />

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: !isOnboarding ? "flex-start" : "center",
          }}
        >
          <Button
            className="btnGo"
            form="edit-company-form"
            type="submit"
            target={false}
            disabled={companyUpdateLoading}
            id={isOnboarding ? "update_company" : ""}
          >
            {t("CompanyForm.Update company")}
          </Button>
        </div>
      </Form>

      <BackdropLoading isOpen={companyUpdateLoading} />

      {isAlert.open && companyUpdateSuccess && (
        <Alert
          isAlert={isAlert.open && companyUpdateSuccess}
          setIsAlert={setIsAlert}
          severity="success"
          message={t("Messages.The operation was successful")}
        />
      )}
      {isAlert.open && isAlert.severity === "warning" && (
        <Alert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
      {isAlert.open && companyUpdateError && (
        <Alert
          isAlert={isAlert.open && companyUpdateError}
          setIsAlert={setIsAlert}
          severity="error"
          message={t("Messages.Something went wrong, please try again")}
        />
      )}
    </>
  );
};

const state = ({ user, companyStore }) => {
  const {
    data: company,
    loading: companyLoading,
    error: companyError,
    success: companySuccess,
  } = companyStore.one;
  const {
    data: companyUpdate,
    loading: companyUpdateLoading,
    error: companyUpdateError,
    success: companyUpdateSuccess,
  } = companyStore.update;
  return {
    user,
    company,
    companyLoading,
    companyError,
    companySuccess,
    companyUpdate,
    companyUpdateLoading,
    companyUpdateError,
    companyUpdateSuccess,
  };
};

export default connect(state)(Component);
