import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  CircularProgress,
  Snackbar,
  FormControlLabel,
  Modal,
  Box,
  Button,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import NavbarPublic from "../../NavbarPublic";
import { useSelector } from "react-redux";

export const LoginStep0 = ({
  error,
  setError,
  loading,
  handlePreSubmit,
  setEmail,
  setPassword,
  setCompanyEmail,
  setStep,
  check,
}) => {
  const { t } = useTranslation();
  const [useCompanyEmail, setUseCompanyEmail] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const state = useSelector((state) => state.companyStore.one.data);

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setIsModalOpen(true);
    } else {
      setUseCompanyEmail(false);
      setCompanyEmail(false);
    }
  };

  const handleModalConfirm = () => {
    setUseCompanyEmail(true);
    setCompanyEmail(true);
    setIsModalOpen(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ height: "100vh" }}>
          <div className="col-xs-12 col-md-6" style={{ paddingTop: "20px" }}>
            <NavbarPublic />

            <form onSubmit={handlePreSubmit} className="mt-3 form-padding">
              <h1 className="main-title">{t("Login.0")}</h1>

              <div className="form-group mt-3">
                <label className="title-inputs">{t("Login.1")}</label>
                <br />
                <br />
                <input
                  type="text"
                  onChange={(e) => setEmail(e.target.value)}
                  className="input-control"
                  placeholder="Email oder #ID"
                  required
                />
              </div>

              <div className="form-group mt-3">
                <label className="title-inputs">{t("Login.2")}</label>
                <br />
                <br />
                <input
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  className="input-control"
                  placeholder="Ihr Passwort"
                  required
                />
              </div>

              <div className="form-group mt-3">
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      style={{ color: "#38baff" }}
                    />
                  }
                  label={t("Login.3")}
                />
              </div>

              <div className="form-group mt-3">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useCompanyEmail}
                      onChange={handleCheckboxChange}
                      color="primary"
                      inputProps={{
                        "aria-label": "use company email checkbox",
                      }}
                      style={{ color: "#38baff" }}
                    />
                  }
                  label={t("LoginInfo.Send 2FA to company Email")}
                  style={{ display: check ? "block" : "none" }} // Hide based on state
                />
              </div>

              {loading ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <>
                  <button
                    type="submit"
                    className="blue-btn"
                    style={{ width: "100%" }}
                  >
                    Log in
                  </button>{" "}
                </>
              )}

              <div className="text-center mt-3">
                <span>{t("Login.4")}</span>
                <a href="/neuertermin" className="blue-anchors">
                  &nbsp;{t("Login.0")}
                </a>
                <br />
                <a href="forgot-password" className="blue-anchors">
                  {t("Login.5")}
                </a>
              </div>
            </form>
          </div>

          <div className="d-none d-sm-block  col-6  login-background"></div>
        </div>
      </div>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setError(false)}
          severity="error"
        >
          {t("Login.6")}
        </MuiAlert>
      </Snackbar>

      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <h3>{t("LoginInfo.Confirmation text")}</h3>
          {state?.emergency_email ? (
            <h5 className="mt-5 mb-5" style={{ fontStyle: "italic" }}>
              Email: {state.emergency_email}
            </h5>
          ) : (
            <p
              style={{ color: "red", marginTop: "20px", marginBottom: "20px" }}
            >
              {t("LoginInfo.NoEmergencyEmail")}
            </p>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleModalConfirm}
            disabled={!state?.emergency_email}
          >
            {t("Buttons.Confirm")}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleModalClose}
            style={{ marginLeft: "10px" }}
          >
            {t("Buttons.Cancel")}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
